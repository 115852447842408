import React from 'react';
import './index.css';

interface IMenu {
	isOpen: boolean;
	setOpen: (value: boolean) => void;
}
const Menu: React.FC<IMenu> = ({ isOpen, setOpen }) => {
	return <div className={`menu-burger md:hidden ${isOpen ? 'fold' : ''}`} onClick={() => setOpen(!isOpen)}>
		<div className="bar1"></div>
		<div className="bar2"></div>
	</div>
}

export default Menu;