export interface ChurchCalendarEvent {
	time: string;
	description?: string;
}

export const scheduleJanurary: Array<ChurchCalendarEvent> = [
	{
		time: "Søndag 7.8. Gudstjeneste kl. 11.00",
		description: "Taler: Kjell Holtet. Sang: Barbra og Monica"
	},
	{
		time: "Søndag 14.8. Gudstjeneste kl. 11.00",
		description: "Taler: Karl Tore Moen. Sang: Blå Kors Musikkor"
	},
	{
		time: "Torsdag 18.8. Medlemsmøte kl. 19.00",
		description: ""
	},
	{
		time: "Søndag 21.8. Gudstjeneste kl. 11.00",
		description: "Taler: Ulf Magne Løvdahl. Sang: Wenche Knudsen"
	},
	{
		time: "Søndag 28.8. Gudstjeneste kl. 11.00",
		description: "Taler: Ulf Magne Løvdahl. Sang: Astrid, Sveinung og Turid"
	}
]

export const scheduleFebruary: Array<ChurchCalendarEvent> = [
	{
		time: "Søndag 4.9. Gudstjeneste kl. 11.00",
		description: "Taler: Oddvar Glidje"
	},
	{
		time: "Torsdag 8.9. Varmestua kl. 19.00",
		description: "Besøk av Unni Bakken m/venner"
	},
	{
		time: "Søndag 11.9. Gudstjeneste kl. 11.00",
		description: "Taler: Ulf Magne Løvdahl"
	},
	{
		time: "Søndag 18.9. Sangmøte kl. 17.00",
		description: "Besøk av Sangvennene Vennesla"
	},
	{
		time: "Torsdag 22.9. Israelmøte kl. 19.00",
		description: "Taler: Ulf Magne Løvdahl"
	},
	{
		time: 'Søndag 25.9. Gudstjeneste kl. 11.00',
		description: 'Taler: Ulf Magne Løvdahl'
	}
]

export const scheduleMarch: Array<ChurchCalendarEvent> = [
	{
		time: "Søndag 2.10. Sangmøte kl. 17.00",
		description: "Besøk av Evangeliesenteret"
	},
	{
		time: "Torsdag 6.10. Varmestua kl. 19.00",
		description: "Besøk av Blå Kors musikkor"
	},
	{
		time: "Søndag 9.10. Gudstjeneste kl. 11.00",
		description: "Taler: Stig Magne Heitmann fra «Åpne Dører». Sang: Familien Kvendset"
	},
	{
		time: "Onsdag 12.10. Konsert kl. 19.00",
		description: "«Good old friends» og Harry Andersen."
	},
	{
		time: "Søndag 16.10. Gudstjeneste kl. 11.00",
		description: "Taler: Gunnar Jeppestøl."
	},
	{
		time: "Torsdag 20.10. Bibel og bønn kl. 19.00"
	},
	{
		time: "Søndag 23.10. Gudstjeneste kl. 11.00",
		description: "Tale og sang av Astrid og Øystein Persson"
	},
	{
		time: "Søndag 30.10. Gudstjeneste kl. 11.00",
		description: "Taler: Bert Stray."
	}
]

export const scheduleApril: Array<ChurchCalendarEvent> = [
	{
		time: "Søndag 6.11. Gudstjeneste kl. 11.00",
		description: "Taler: Ulf Magne Løvdahl. Sang: Elisabeth Gjerde"
	},
	{
		time: "Søndag 13.11. Gudstjeneste kl. 11.00",
		description: "Taler: Ulf Magne Løvdahl"
	},
	{
		time: "Torsdag 17.11. Medlemsmøte kl. 19.00"
	},
	{
		time: "Søndag 20.11. Gudstjeneste kl. 11.00",
		description: "Taler: Ulf Magne Løvdahl"
	},
	{
		time: "Torsdag 24.11. Israelmøte kl. 19.00",
		description: "Taler: Dag Øyvind Juliussen"
	},
	{
		time: "Søndag 27.11. Gudstjeneste kl. 11.00",
		description: "Besøk av Bente Hinz og Sigrid Kvendset fra «Operasjon Mobilisering»"
	}
]

export const scheduleMay: Array<ChurchCalendarEvent> = [
	{
		time: "Søndag 4.12. Gudstjeneste kl. 11.00",
		description: "Taler: Harald Kjevik.  Sang: Tom Gabrielsen m/venner"
	},
	{
		time: "Søndag 11.12. Gudstjeneste kl. 11.00",
		description: "Tale: Dag Frode Vaagsnes"
	},
	{
		time: "Torsdag 15.12. Varmestua kl. 19.00",
		description: "Besøk av Vidar Søyland m.fl."
	},
	{
		time: "Søndag 18.12. Gudstjeneste kl. 11.00",
		description: "Tale: Even Valderhaug"
	}
]

export const scheduleJune: Array<ChurchCalendarEvent> = [
	{
		time: "Torsdag 2.6. Varmestua kl.19.00",
		description: "Besøk av Evangelieteamet"
	},
	{
		time: "Søndag 5.6. Gudstjeneste kl. 11.00",
		description: "Taler: Sven Gunvaldsen"
	},
	{
		time: "Søndag 12.6. Gudstjeneste kl. 11.00",
		description: "Tale: Ulf Magne Løvdahl"
	},
	{
		time: "Torsdag 16.6. Israelmøte kl. 19.00",
		description: "Tale: Gordon Tobiassen"
	},
	{
		time: "Søndag 19.6. Sommeravslutning kl. 11.00",
		description: "Tale: Even Valderhaug"
	},
	{
		time: "Søndag 26.6. Felles Gudstjeneste kl. 11.00"
	}
]