import React, { createRef } from 'react';
import './App.css';
import chevronDown from './images/chevron-down.svg';
import Calendar from './components/Calendar';
import Header from './components/Header';
import vippsLogo from './images/vipps.svg';
import menighetsraadet from './images/menighetsraadet.jpg';

function App() {
  const infoRef = createRef<HTMLParagraphElement>()
  return (
    <div className="page-container">
      <Header />
      <div className="header_section">
        <div id="header_image">
          <div className="blur" />
        </div>
        <div id="header_section_info">
          <div id="header-section-title">
            <h1 >Velkommen til Baptistkirken</h1>
            <p>En menighet for lillesand og omegn</p>
          </div>
          <div className="activities">
            <div className="activity-circle">
              <p>Gudstjeneste</p>
            </div>
            <div className="activity-circle">
              <p>Fellesskap</p>
            </div>
            <div className="activity-circle">
              <p>Musikk</p>
            </div>
          </div>
          <div id="read_more" className="center">
            <p onClick={() => {
              if (infoRef.current) {
                infoRef.current.scrollIntoView();
              }
            }}>Les Mer</p>
            <img src={chevronDown} />
          </div>

        </div>

      </div>
      <div className="section" >

        <p className="text-2xl text-center" id="hva-skjer" ref={infoRef}>Hva skjer i baptistkirken</p>


        <Calendar />
        <div className="border-b border-slate-300" />
      </div>
      <div className="section ">
        <div className="lg:px-72 mb-8">
          <h1 className="text-2xl text-center mb-8" id="gi-gave">Vil du gi en gave?</h1>
          <p className="mb-7 text-center flex justify-center">
            Alle frimenigheter drives av frivillige gaver fra menighetens
            medlemmer og venner. Hvis du ønsker å være med å støtte menighetens
            arbeid kan du gjøre det enten på vipps eller de kontonummer
            som er oppgitt nedenfor.
            <br /><br />
            Det er mulig å få skattefradrag for gaver til menigheten.
            Da må vi i tilfelle også ha ditt personnummer og rapportere
            gaven inn til skattemyndighetene.
            Vi har et eget kontonummer for slike gaver.
          </p>
          <div className="flex items-center">
            <img src={vippsLogo} className="h-12" />
            <p className="font-bold text-lg ml-4">127184</p>
          </div>
          <p className="text-lg">Kontonummer - <b>2850.21.31828</b></p>
          <p className="text-lg">Kontonummer for gaver med skattefradrag - <b>2850.69.05526</b></p>
        </div>

        <div className="border-b border-slate-300" />
      </div>
      <div className="section md:px-16">
        <h1 className="text-2xl text-center mb-8" id="kontakt">Kontakt oss</h1>

        <div className="md:flex items-center justify-center">
          <img className="hidden   md:flex h-96 rounded-lg" src={menighetsraadet} />

          <p className="ml-8">
            Postadresse: Lillesand Baptistmenighet, postboks 179, 4792 Lillesand.<br />
            Besøksadresse: Birkelandsveien 126, Fyresmoen, Lillesand<br />
            Epost: lillesandbaptistmenighet@outlook.com<br />
            <br /><br />
            Menighetsrådet:<br />
            Forstander, Even Valderhaug, tlf 926 82 049<br />
            Viseforstander, Ulf Magne Løvdahl, tlf 402 11 717<br />
            Barbra Michaelsen<br />
            Monica Hystad<br />
            Torunn Karlsen<br />
          </p>
          <div className="flex md:hidden justify-center">
            <img className=" h-48 rounded-full" src={menighetsraadet} />
          </div>

        </div>

      </div>
      <div id="footer" className="p-8 text-white text-sm">
        <p className="my-2"><b>Besøksaddresse:</b> Birkelandsveien 126, Lillesand</p>
        <p className="my-2"><b>Postadresse:</b> Lillesand Baptistmenighet, postboks 179, 4792 Lillesand.</p>
        <p className="my-2"><b>E-post:</b> lillesandbaptistmenighet@outlook.com</p>
        <p className="my-2"><b>Organisasjonsnummer:</b> 971494689</p>
      </div>
    </div>
  );
}

export default App;
