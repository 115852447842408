import React, { useState } from "react";
import logo from '../../images/logo.svg';
import Menu from "../Menu";
import facebookLogo from '../../images/facebook.svg';

const Header = () => {
	const [isOpen, setOpen] = useState(false);
	const onLinkClick = () => {
		setOpen(false);
	}

	const links = (

		<><li className="nav-menu-item" onClick={onLinkClick}><a href="#hva-skjer">Hva skjer?</a></li>
			<li className="nav-menu-item" onClick={onLinkClick}><a href="#program">Program</a></li>
			<li className="nav-menu-item" onClick={onLinkClick}><a href="#kontakt">Kontakt</a></li>
			<li className="nav-menu-item" onClick={onLinkClick}><a href="#gi-gave">Gi gave</a></li></>)
	return <div className="relative sticky top-0 z-10 "><header className="header  justify-between">
		<img src={facebookLogo} className="md:hidden h-8 hover:cursor-pointer" onClick={() => window.open('https://www.facebook.com/Lillesand-Baptistkirke-107096607653548', "_blank")} />
		<div className="logo-section w-full md:w-max flex justify-center">
			<img src={logo} />
		</div>
		<ul className="hidden md:flex">
			{links}
		</ul>
		<div className="hidden md:flex items-center hover:cursor-pointer" onClick={() => window.open('https://www.facebook.com/Lillesand-Baptistkirke-107096607653548', "_blank")}>
			<p className="mr-2 text-sm">Hold deg oppdater på facebook</p>
			<img src={facebookLogo} className="h-8" />
		</div>


		<Menu isOpen={isOpen} setOpen={setOpen} />
		{isOpen && <div className="filter drop-shadow pl-8 absolute top-20 left-0 bg-white z-10 w-full">
			<ul className="">
				{links}
			</ul>
		</div>}

	</header></div>
}

export default Header;