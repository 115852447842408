import React from 'react';
import { ChurchCalendarEvent, scheduleApril, scheduleFebruary, scheduleJanurary, scheduleJune, scheduleMarch, scheduleMay } from './schedule';
import './index.css';

const Calendar = () => {
	return <div id="calendar_section">


		<p className="mb-7 mt-8" id="program">Program</p>
		<h2 className="font-bold mb-4">Første halvår 2022</h2>
		<div id="calendar" >
			<div className="calendar-section justify-start lg:border-r lg:px-2">
				<div className="mb-16">
					<p className="mb-8"><b>August</b></p>
					<ul>
						{scheduleJanurary.map(event => {
							return <CalendarEvent time={event.time} description={event.description} />
						})}
					</ul>
				</div>
				<div>
					<p className="mb-8"><b>September</b></p>
					<ul>
						{scheduleFebruary.map(event => {
							return <CalendarEvent time={event.time} description={event.description} />
						})}
					</ul>
				</div>
			</div>
			<div className="calendar-section lg:border-r lg:px-2">
				<div className="mb-16">
					<p className="mb-8"><b>Oktober</b></p>
					<ul>
						{scheduleMarch.map(event => {
							return <CalendarEvent time={event.time} description={event.description} />
						})}
					</ul>
				</div>
				<div>
					<p className="mb-8"><b>November</b></p>
					<ul>
						{scheduleApril.map(event => {
							return <CalendarEvent time={event.time} description={event.description} />
						})}
					</ul>
				</div>
			</div>
			<div className="calendar-section">
				<div className="mb-16">
					<p className="mb-8"><b>Desember</b></p>
					<ul>
						{scheduleMay.map(event => {
							return <CalendarEvent time={event.time} description={event.description} />
						})}
					</ul>
				</div>
			</div>


		</div>
		<p>Møter i jule- og nyttårshelgen vil bli annonsert senere.</p>
		<div className="shadow bg-blue-300 p-4 mt-4 mb-4 md:w-1/2">Endringer kan skje i programmet.  Følg med i annonsene på <a target="_blank" href="https://www.facebook.com/Lillesand-Baptistkirke-107096607653548" className="underline">Facebook</a> og i Lillesandsposten.</div>
	</div >
}


const CalendarEvent: React.FC<ChurchCalendarEvent> = ({ time, description }) => {
	return <li className="calendar-event">
		<p>{time}</p>
		<p>{description}</p>
	</li>
}


export default Calendar;
